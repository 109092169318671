<template>
  <div>
    <el-row type="flex"
            justify="end">
      <!-- <el-button type="info">关闭会话</el-button> -->
      <el-button @click="$router.go(-1)">返回</el-button>
    </el-row>
    <el-row type="flex"
            justify="center">
      <el-col :span="20">
        <el-row>
          <el-form label-width="100px">
            <el-col :span="6">
              <el-form-item label="意见ID:">{{ detailInfo.memberAdviseId }}</el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="手机号:">{{ detailInfo.mobile }}</el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="类型:">
                {{ detailInfo.adviseType | adviseTypeFilter }}
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="状态:">
                {{ detailInfo.operateType | operateTypeFilter }}
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </el-col>
    </el-row>
    <el-row type="flex"
            justify="center">
      <el-col :span="20" v-if="detailInfo.parkRecordId">
        <el-row>
          <el-form label-width="100px">
            <el-col :span="6">
              <el-form-item label="停车订单ID:">{{ detailInfo.parkRecordVO.parkRecordId }}</el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="车牌号:">{{ detailInfo.parkRecordVO.plateNumber }}</el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="停车场名称:">
                {{ detailInfo.parkRecordVO.parkName }}
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="入场时间:">
                {{ detailInfo.parkRecordVO.strEntryTime }}
              </el-form-item>
            </el-col>
             <el-col :span="6">
              <el-form-item label="出场时间:">
                {{ detailInfo.parkRecordVO.strExitTime }}
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
           <el-button v-if="detailInfo.parkRecordVO.appealDealStatus != 0" class="seeDetail" @click="seeDetails(detailInfo.parkRecordVO)">去处理</el-button>
      <el-button v-if="detailInfo.parkRecordVO.appealDealStatus == 0" class="seeDetail"  @click="openTips">处理中</el-button>
      </el-col>
   
    </el-row>
    <el-row type="flex"
            justify="center">
      <el-col :span="20">
        <el-card>
          <div class="dialog-box">
            <div class="title">意见反馈</div>
            <div class="container">
              <div v-for="(data, index) in dialogueDetail" :key="index">
                <div class="conversation customer" v-if="data.content">
                <div class="date-time">{{ data.adviseTime }}</div>
                <div class="content">
                  <div>意见内容:</div>
                  <div>
                    <div style="word-break: break-all">
                      {{ data.content }}
                    </div>
                    <div>
                      <el-popover v-for="(item, index) in data.askImageList"
                                  :key="index"
                                  placement="left"
                                  popper-class="poperStyle"
                                  trigger="hover">
                        <img :src="item"
                             style="width: 400px">
                        <img slot="reference"
                             :src="item"
                             style="height: 50px; margin-right: 10px; margin-top: 10px" />
                      </el-popover>
                    </div>
                  </div>
                </div>
              </div>
               <div v-if="data.operateType !== 1"
                   class="conversation service">
                <div class="date-time">{{ data.replyTime }}</div>
                <div class="content">
                  <div style="width: 110px">{{ data.sysUserName }} 回复:</div>
                  <div>
                    <div style="word-break: break-all">
                      {{ data.replyContent }}
                    </div>
                    <div>
                      <el-popover v-for="(item, index) in data.answerImageList"
                                  :key="index"
                                  placement="left"
                                  popper-class="poperStyle"
                                  trigger="hover">
                        <img :src="item"
                             style="width: 400px">
                        <img slot="reference"
                             :src="item"
                             style="height: 50px; margin-right: 10px; margin-top: 10px" />
                      </el-popover>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <!-- <div v-if="detailInfo.operateType !== 1"
                   class="conversation service">
                <div class="date-time">{{ detailInfo.replyTime }}</div>
                <div class="content">
                  <div style="width: 110px">{{ detailInfo.sysUserName }} 回复:</div>
                  <div>
                    <div style="word-break: break-all">
                      {{ detailInfo.replyContent }}
                    </div>
                    <div>
                      <el-popover v-for="(item, index) in answerImageList"
                                  :key="index"
                                  placement="left"
                                  popper-class="poperStyle"
                                  trigger="hover">
                        <img :src="item"
                             style="width: 400px">
                        <img slot="reference"
                             :src="item"
                             style="height: 50px; margin-right: 10px; margin-top: 10px" />
                      </el-popover>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
            <div
                 style="padding: 10px 4px;">
              <el-input type="textarea"
                        rows="4"
                        v-model.trim="replyContent"
                        maxlength="200"
                        show-word-limit
                        style="margin-bottom: 10px" />
              <div class="imageList">
                <div v-for="(item, index) in fileList"
                     :key="index"
                     class="imageItem">
                  <i class="el-icon-error close-icon"
                     @click="deleteImage(index)" />
                  <el-popover placement="left"
                              popper-class="poperStyle"
                              trigger="hover">
                    <img :src="item"
                         style="width: 400px">
                    <img slot="reference"
                         :src="item" />
                  </el-popover>
                </div>
              </div>
              <div style="display: flex; justify-content: space-between">
                <el-upload :on-remove="handleRemove"
                           :on-success="handleSuccess"
                           :on-change="getMMM"
                           name='image'
                           :headers='uploadHeader'
                           :http-request="handleFiles"
                           action
                           :show-file-list="false">
                  <AuthorityComponent ComponentName="el-button"
                                      :permission="['subPage.detail.button.reply']"
                                      size="small"
                                      type="primary">点击上传</AuthorityComponent>
                </el-upload>
                <AuthorityComponent ComponentName="el-button"
                                    :permission="['subPage.detail.button.reply']"
                                    type="success"
                                    @click="showVisible">回复</AuthorityComponent>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog title="回复内容确认"
               :visible.sync="dialogVisible">
      <span>{{ replyContent }}</span>
      <div style="margin-top: 40px"
           class="imageList">
        <el-popover v-for="(item, index) in fileList"
                    :key="index"
                    placement="left"
                    popper-class="poperStyle"
                    trigger="hover">
          <img :src="item"
               style="width: 400px">
          <img slot="reference"
               :src="item" />
        </el-popover>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   :loading="loading"
                   @click="replyConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <processException
      ref="processEc"
      :dialogVisibleException="dialogVisibleException"
      @consoleException="consoleException"
      :tableException="tableDetails"
    ></processException>
  </div>
</template>

<script>
import processException from "./processException";
export default {
  filters: {
    operateTypeFilter (val) {
      if (!val) {
        return
      }
      const result = ['', '待回复', '', '已回复']
      return result[val]
    },
    adviseTypeFilter (val) {
      if (!val) {
        return
      }
      const result = ['', '停车服务', '订单服务', '车辆管理', '功能异常', '商务合作', '产品建议', '其他']
      return result[val]
    }
  },
  components: {
    processException
  },
  data () {
    return {
      textNumber: 0,
      fileList: [],
      image: [],
      dialogVisible: false,
      memberAdviseId: this.$route.query.memberAdviseId,
      detailInfo: {},
      replyContent: '',
      uploadHeader: {
        'Session-Id': sessionStorage.token
      },
      loading: false,
      askImageList: [],
      answerImageList: [],
      authority: this.$route.meta.authority.button,
      dialogueDetail: [],
      dialogVisibleException: false,
      tableDetails: [
        {
          prop: "parkRecordId",
          label: this.$t("list.Parking_Order_ID"),
          width: "180",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "150",
        },
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "",
        },
        {
          prop: "strEntryTime",
          label: this.$t("list.entry_time"),
          width: "180",
        },
        {
          prop: "strExitTime",
          label: this.$t("list.Appearance_time"),
          width: "180",
        }
      ]
    }
  },
  mounted () {
    this.getDetail()
  },
  methods: {
    openTips() {
      this.$alert(
        "当前记录已存在等待处理的申诉记录, 请前往处理或修改处理方案",
        this.$t('pop_up.Tips'),
        {
          confirmButtonText: this.$t('pop_up.Determine'),
        }
      );
    },
    // 获取详情信息
    getDetail () {
      const opt = {
        url: '/acb/2.0/member/advise/detail',
        method: 'get',
        data: {
          memberAdviseId: this.memberAdviseId
        },
        success: res => {
          this.detailInfo = res.value
          this.dialogueDetail = res.value.dialogueDetail
          for (var v = 0; v < this.dialogueDetail.length; v++) {
            let answerImage = this.dialogueDetail[v].answerImgMap
            if (answerImage) {
            this.askImageList = []
            this.answerImageList = []
            for (const i in answerImage) {
              if (i < 100) {
                this.askImageList.push(answerImage[i])
                this.dialogueDetail[v].askImageList = this.askImageList
              } else {
                this.answerImageList.push(answerImage[i])
                console.log(this.answerImageList, 'this.answerImageList')
                this.dialogueDetail[v].answerImageList = this.answerImageList
              }
            }
            }
          }
        }
      }
      this.$request(opt)
    },
    seeDetails(data) {
      console.log(data, 'dadsa')
      this.dialogVisibleException = true;
      this.$refs.processEc.refundRecordIdFn(data.parkRecordId);
    },
    consoleException() {
      this.dialogVisibleException = false;
      this.getDetail()
    },
    // 删除图片
    handleRemove (file, fileList) {
      this.fileList = fileList
    },
    getMMM (file) {
      if (this.fileList.length > 5) {
        this.$message.warning('只能上传6张图片')
        this.textNumber = 1
      } else {
        let X = 0;
        let Y = 0;
        const suffix = file.name.substring(file.name.lastIndexOf('.') + 1)
        const isJpg = suffix === 'jpg'
        const isjpeg = suffix === 'gif'
        const isPng = suffix === 'png'
        const isBmp = suffix === 'bmp'
        const isLt2M = file.size / 1024 / 1024 < 5
        if (!isJpg && !isjpeg && !isPng && !isBmp) {
          this.$message.warning('图片格式错误，只支持jpg、gif、bmp和png格式!')
          X = 1;
        } else if (!isLt2M) {
          this.$message.warning('上传图片大小不能超过 5M!')
          Y = 1;
        }
        if (X == 1 || Y == 1) {
          this.textNumber = 1;
        } else {
          this.textNumber = 0;
        }
      }
    },
    // 上传前的验证
    // beforeUpload (file) {
    //   const suffix = file.name.substring(file.name.lastIndexOf('.') + 1)
    //   const isJpg = suffix === 'jpg'
    //   const isjpeg = suffix === 'gif'
    //   const isPng = suffix === 'png'
    //   const isBmp = suffix === 'bmp'
    //   const isLt2M = file.size / 1024 / 1024 < 5
    //   if (!isJpg && !isjpeg && !isPng && !isBmp) {
    //     this.$message.warning('图片格式错误，只支持jpg、gif、bmp和png格式!')
    //     return false
    //   }
    //   if (!isLt2M) {
    //     this.$message.warning('上传图片大小不能超过 5M!')
    //     return false
    //   }
    //   return true
    // },
    // 上传成功回调
    handleSuccess (res, file, fileList) {

    },
    handleFiles (file) {
      if (this.textNumber == 1) {
        return false;
      } else {
        const fileItem = URL.createObjectURL(file.file)
        this.fileList.push(fileItem)
        this.image.push(file.file)
      }
      // const a = this.fileList.filter(item => {
      //   return item.constructor !== Object
      // })
      // this.fileList = a
    },
    // 删除图片
    deleteImage (index) {
      this.fileList.splice(index, 1)
      this.image.splice(index, 1)
    },
    // 确认回复
    replyConfirm () {
      const image = new FormData()
      this.image.forEach((item, index) => {
        image.append('image', item, 'img' + index)
      })
      let replyContent = encodeURI(this.replyContent)
      const opt = {
        url: `/acb/2.0/member/advise/reply?memberAdviseId=${this.detailInfo.memberAdviseId}&content=${replyContent}`,
        method: 'post',
        data: image,
        config: {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        },
        success: res => {
          this.loading = false
          this.dialogVisible = false
          this.replyContent = ''
          this.getDetail()
        },
        fail: err => {
          this.loading = false
          this.dialogVisible = false
        }
      }
      this.$request(opt)
    },
    // 显示确认弹窗
    showVisible () {
      if (!this.replyContent) {
        this.$alert('回复内容不能为空', this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine')
        })
        return
      }
      this.dialogVisible = true
    }
  }
}
</script>

<style lang="stylus" scoped>
.dialog-box
  border 1px solid #ccc
  .title
    height 42px
    line-height 42px
    background-color #ccc
    padding-left 20px
    font-size 16px
  .container
    padding 40px
    display flex
    flex-direction column
    .conversation
      padding 20px
      width 65%
      margin-bottom 30px
      &.customer
        background-color rgb(243, 230, 202)
      &.service
        background-color rgb(219, 242, 203)
        align-self flex-end
      .date-time
        margin-bottom 10px
      .content
        display flex
        & > div
          line-height 1.5em
          &:first-child
            width 70px
            flex-shrink 0
.imageList
  padding 10px 0
  img
    width 50px
    margin-right 16px
.imageItem
  display inline-block
  position relative
  .close-icon
    position absolute
    right 6px
    top -9px
    font-size 18px
    color red
    cursor pointer
.seeDetail
  position: absolute;
  top: 0px;
  right: 0px;
</style>
