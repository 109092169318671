<template>
  <div class="processDetails">
    <el-dialog
      title="异常订单处理记录审核"
      :visible="dialogVisibleException"
      @close="consoleDetail"
      width="70%"
    >
      <h2 class="title" style="padding-top: 10px">
        <div class="title_icon"></div>
        <span class="titlecontent">停车订单列表</span>
      </h2>
      <!-- 表格 -->
      <div class="tableError">
        <el-table
          :data="tableData"
          style="width: 100%"
          :row-key="getRowKeys"
          @expand-change="exChange"
          :expand-row-keys="expands"
          :header-row-class-name="headerTitle"
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <!-- 详情 -->
              <div class="carDetail">
                <h2 class="parkingInfo">
                  车场详情
                  <div class="parkingInfoLine"></div>
                </h2>
                <el-row>
                  <el-col :span="12" class="detailsLeft">
                    <div class="detailsItem">
                      <span class="detailsSpan">停车场名称：</span>
                      <span :class="formData.treatmentVlaue == 5 ? 'ustyleLine' : ''">{{
                        props.row.parkName
                      }}</span>
                    </div>
                    <div class="detailsItem">
                      <span class="detailsSpan">商户名称:</span>
                      <span :class="formData.treatmentVlaue == 5 ? 'ustyleLine' : ''">{{
                        props.row.operationName
                      }}</span>
                    </div>
                    <div class="detailsItem">
                      <span class="detailsSpan">车场类型:</span>
                      <span :class="formData.treatmentVlaue == 5 ? 'ustyleLine' : ''">{{
                        props.row.parkTypeName
                      }}</span>
                    </div>
                  </el-col>
                  <el-col :span="12" class="detailsLeft">
                    <!-- <div class="detailsItem">
                      <span class="detailsSpan">城市：</span>
                      <span
                        :class="
                          formData.treatmentVlaue == 5 ? 'ustyleLine' : ''
                        "
                        >{{ props.row.cityName }}</span
                      >
                    </div> -->
                    <div class="detailsItem">
                      <span class="detailsSpan">区域：</span>
                      <span :class="formData.treatmentVlaue == 5 ? 'ustyleLine' : ''">{{
                        props.row.areaName
                      }}</span>
                    </div>
                    <div class="detailsItem">
                      <span class="detailsSpan">泊位号：</span>
                      <span :class="formData.treatmentVlaue == 5 ? 'ustyleLine' : ''">{{
                        props.row.berthCode
                      }}</span>
                    </div>
                  </el-col>
                </el-row>
                <h2 class="parkingInfo">
                  车场详情
                  <div class="parkingInfoLine"></div>
                </h2>
                <el-row>
                  <el-col :span="12" class="detailsLeft">
                    <div class="detailsItem">
                      <span class="detailsSpan">车牌号：</span>
                      <!-- <span>{{ props.row.plateNumber }}</span> -->
                      <span style="" v-if="props.row.testPlateNumber">
                        <!-- 修改时间之前的数据 -->
                        <u class="ustyleLine">{{ props.row.testPlateNumber }}</u>
                        <!-- 修改之后的数据 接口获得-->
                        <a class="uStyleItem"> {{ props.row.plateNumber }}</a>
                      </span>
                      <!-- 普通展开没有修改时间 -->
                      <span
                        style=""
                        v-else
                        :class="formData.treatmentVlaue == 5 ? 'ustyleLine' : ''"
                      >
                        {{ props.row.plateNumber }}
                      </span>
                    </div>
                    <div class="detailsItem">
                      <span class="detailsSpan">入场时间：</span>
                      <span :class="formData.treatmentVlaue == 5 ? 'ustyleLine' : ''">{{
                        props.row.strEntryTime
                      }}</span>
                    </div>
                    <div class="detailsItem">
                      <span class="detailsSpan">入场记录来源：</span>
                      <span :class="formData.treatmentVlaue == 5 ? 'ustyleLine' : ''">{{
                        props.row.entryDataSourceName
                      }}</span>
                    </div>
                    <div class="detailsItem">
                      <span class="detailsSpan">出入场图像：</span>
                      <span
                        class="orderNumberStyle"
                        style="cursor: pointer"
                        @click="lookPicture(props.row)"
                        >查看</span
                      >
                    </div>
                  </el-col>
                  <el-col :span="12" class="detailsLeft">
                    <div class="detailsItem">
                      <span class="detailsSpan">停车时长：</span>
                      <!-- <span>{{ props.row.parkTime }}</span> -->
                      <span v-if="props.row.testStrParkTime">
                        <!-- 修改时间之前的数据 -->
                        <u class="ustyleLine">{{ props.row.testStrParkTime }}</u>
                        <!-- 修改之后的数据 接口获得-->
                        <a class="uStyleItem"> {{ props.row.parkTime }}</a>
                      </span>
                      <!-- 普通展开没有修改时间 -->
                      <span v-else :class="formData.treatmentVlaue == 5 ? 'ustyleLine' : ''">
                        {{ props.row.parkTime }}
                      </span>
                    </div>
                    <div class="detailsItem">
                      <span class="detailsSpan">出场时间：</span>
                      <!-- <span>{{ props.row.strExitTime }}</span> -->
                      <span v-if="props.row.testStrExitTime == '无'">
                        <!-- 修改之后的数据 接口获得-->
                        <a class="uStyleItem"> {{ props.row.strExitTime }}</a>
                      </span>
                      <span
                        v-else-if="
                          props.row.testStrExitTime &&
                          props.row.strExitTime != props.row.testInitTime
                        "
                      >
                        <!-- 修改时间之前的数据 -->
                        <u class="ustyleLine">{{ props.row.testStrExitTime }}</u>
                        <!-- 修改之后的数据 接口获得-->
                        <a class="uStyleItem"> {{ props.row.strExitTime }}</a>
                      </span>
                      <!-- 普通展开没有修改时间 -->
                      <span v-else :class="formData.treatmentVlaue == 5 ? 'ustyleLine' : ''">
                        {{ props.row.strExitTime }}
                      </span>
                    </div>
                    <div class="detailsItem">
                      <span class="detailsSpan">出场记录来源：</span>
                      <!-- <span>{{ props.row.exitDataSourceName }}</span> -->
                      <span v-if="props.row.testStrExitDataSourceName">
                        <!-- 修改时间之前的数据 -->
                        <u class="ustyleLine">{{ props.row.testStrExitDataSourceName }}</u>
                        <!-- 修改之后的数据 接口获得-->
                        <a class="uStyleItem"> {{ props.row.exitDataSourceName }}</a>
                      </span>
                      <!-- 普通展开没有修改时间 -->
                      <span v-else :class="formData.treatmentVlaue == 5 ? 'ustyleLine' : ''">
                        {{ props.row.exitDataSourceName }}
                      </span>
                    </div>
                  </el-col>
                </el-row>
                <h2 class="parkingInfo">
                  车辆详情
                  <div class="parkingInfoLine"></div>
                </h2>
                <el-row>
                  <el-col :span="12" class="detailsLeft">
                    <div class="detailsItem">
                      <span class="detailsSpan">应收金额：</span>
                      <!-- 修改出场时间 -->
                      <span
                        v-if="
                          (props.row.originSumShouldPay || props.row.originSumShouldPay == 0) &&
                          props.row.originSumShouldPay != props.row.modifySumShouldPay
                        "
                      >
                        <!-- 修改时间之前的数据 -->
                        <u class="ustyleLine">{{ props.row.originSumShouldPay / 100 }}元</u>
                        <!-- 修改之后的数据 接口获得-->
                        <a class="uStyleItem"> {{ props.row.modifySumShouldPay / 100 }}元</a>
                      </span>
                      <!-- 普通展开没有修改时间 -->
                      <span v-else :class="formData.treatmentVlaue == 5 ? 'ustyleLine' : ''">
                        {{ props.row.shouldPay / 100 }}元
                      </span>
                    </div>
                    <!-- <div class="detailsItem">
                         <span class="detailsSpan">抵扣金额：</span>
                         <span>{{  props.row.totalPreMoney ? props.row.totalPreMoney / 100 + '元' : '-' }}</span>
                      </div> -->
                    <div class="detailsItem">
                      <span class="detailsSpan">优惠券:</span>
                      <span
                        v-if="
                          (props.row.originSumDiscount || props.row.originSumDiscount == 0) &&
                          props.row.originSumDiscount != props.row.modifySumDiscount
                        "
                      >
                        <!-- 修改时间之前的数据 -->
                        <u class="ustyleLine">{{ props.row.originSumDiscount / 100 }}元</u>
                        <!-- 修改之后的数据 接口获得-->
                        <a class="uStyleItem"> {{ props.row.modifySumDiscount / 100 }}元</a>
                      </span>
                      <!-- 普通展开没有修改时间 -->
                      <span v-else :class="formData.treatmentVlaue == 5 ? 'ustyleLine' : ''">
                        {{ props.row.sumAgioMoney ? props.row.sumAgioMoney / 100 + "元" : "-" }}
                      </span>
                    </div>
                  </el-col>
                  <el-col :span="12" class="detailsLeft">
                    <div class="detailsItem">
                      <span class="detailsSpan">优惠总计：</span>
                      <!-- <span> {{ props.row.agioPay / 100 }}元 </span> -->
                      <span
                        v-if="
                          (props.row.originSumAgioMoney || props.row.originSumAgioMoney == 0) &&
                          props.row.originSumAgioMoney != props.row.modifySumAgioMoney
                        "
                      >
                        <!-- 修改时间之前的数据 -->
                        <u class="ustyleLine">{{ props.row.originSumAgioMoney / 100 }}元</u>
                        <!-- 修改之后的数据 接口获得-->
                        <a class="uStyleItem"> {{ props.row.modifySumAgioMoney / 100 }}元</a>
                      </span>
                      <!-- 普通展开没有修改时间 -->
                      <span v-else :class="formData.treatmentVlaue == 5 ? 'ustyleLine' : ''">
                        <!-- {{ props.row.agioPay / 100 }}元 -->
                        {{ props.row.totalDiscount / 100 }}元
                      </span>
                    </div>
                    <div class="detailsItem">
                      <span class="detailsSpan">实付总计：</span>
                      <!-- <span> {{ props.row.actualPay / 100 }}元 </span> -->
                      <span
                        v-if="
                          (props.row.originSumActualMoney || props.row.originSumActualMoney == 0) &&
                          props.row.originSumActualMoney != props.row.modifySumActualMoney
                        "
                      >
                        <!-- 修改时间之前的数据 -->
                        <u class="ustyleLine">{{ props.row.originSumActualMoney / 100 }}元</u>
                        <!-- 修改之后的数据 接口获得-->
                        <a class="uStyleItem"> {{ props.row.modifySumActualMoney / 100 }}元</a>
                      </span>
                      <!-- 普通展开没有修改时间 -->
                      <span v-else :class="formData.treatmentVlaue == 5 ? 'ustyleLine' : ''">
                        <!-- {{ props.row.actualPay / 100 }}元 -->
                        {{ props.row.sumActualPay / 100 }}元
                      </span>
                      <span style="opacity: 0.5" v-if="props.row.totalPreMoney">
                        (预付抵扣金额：{{
                          props.row.totalPreMoney ? props.row.totalPreMoney / 100 + "元" : "-"
                        }})</span
                      >
                    </div>
                    <div class="detailsItem">
                      <span class="detailsSpan">欠费总计：</span>
                      <!-- <span>
                        {{
                          props.row.lackMoney ? props.row.lackMoney / 100 : "0"
                        }}元
                      </span> -->
                      <span
                        v-if="
                          (props.row.originSumLackMoney || props.row.originSumLackMoney == 0) &&
                          props.row.originSumLackMoney != props.row.modifySumLackMoney
                        "
                      >
                        <!-- 修改时间之前的数据 -->
                        <u class="ustyleLine">{{ props.row.originSumLackMoney / 100 }}元</u>
                        <!-- 修改之后的数据 接口获得-->
                        <a class="uStyleItem"> {{ props.row.modifySumLackMoney / 100 }}元</a>
                      </span>
                      <!-- 普通展开没有修改时间 -->
                      <span v-else :class="formData.treatmentVlaue == 5 ? 'ustyleLine' : ''">
                        {{ props.row.lackMoney / 100 }}元
                      </span>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            :label="item.label"
            :width="item.width"
            v-for="item in tableException"
            :key="item.prop"
          >
            <template slot-scope="scope">
              <span
                v-if="item.prop == 'parkRecordId'"
                v-clipboard:copy="scope.row.parkRecordId"
                v-clipboard:success="onCopy"
                class="orderNumberStyle"
              >
                <p :class="formData.treatmentVlaue == 5 ? 'ustyleLine' : ''">
                  {{ scope.row.parkRecordId }}
                </p>
              </span>
              <span v-else-if="item.prop == 'strExitTime'">
                <p v-if="formData.treatmentVlaue == 5" class="ustyleLine">
                  {{ scope.row.strExitTime }}
                </p>
                <p
                  v-else
                  :class="
                    scope.row.testStrExitTime && scope.row.strExitTime != scope.row.testInitTime
                      ? 'orderNumberStyle'
                      : ''
                  "
                >
                  {{ scope.row.strExitTime }}
                </p>
              </span>
              <span v-else>
                <p :class="formData.treatmentVlaue == 5 ? 'ustyleLine' : ''">
                  {{ item.formatter ? item.formatter(scope.row) : scope.row[item.prop] }}
                </p>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--异常停车处理信息 -->
      <el-form ref="formData" :model="formData" :rules="rules">
        <div>
          <h2 class="title" style="padding-top: 10px">
            <div class="title_icon"></div>
            <span class="titlecontent">异常停车处理信息</span>
          </h2>
          <!-- <h2 class="parkingInfo">处理理由：</h2> -->
          <h2 class="parkingInfo">
            处理理由
            <div class="parkingInfoLine"></div>
          </h2>
          <el-form-item label="" prop="textarea">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="formData.textarea"
              maxlength="30"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </div>
        <!-- 处理方式 -->
        <div>
          <h2 class="parkingInfo">
            处理方式
            <div class="parkingInfoLine"></div>
          </h2>
          <el-row>
            <el-col :span="7">
              <el-form-item label="更改项:" prop="treatmentVlaue">
                <el-select
                  v-model="formData.treatmentVlaue"
                  placeholder="请选择"
                  @change="submitChange"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7" v-if="formData.treatmentVlaue == 1">
              调整基于:
              <el-select v-model="timeChange" placeholder="请选择" @change="timeErrChange">
                <el-option
                  v-for="item in timeChangeoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="8" v-if="formData.treatmentVlaue == 1 && timeChange == 2">
              调整为: 入场时间 +
              <!-- <el-input-number v-model="timeInput" controls-position="right"  @blur="search" :min="1" :max="999999"></el-input-number> -->
              <el-input
                placeholder="请输入"
                v-model="timeInput"
                :maxlength="6"
                style="width: 100px"
                oninput="value=value.replace(/[^\d]/g,'')"
                @blur="search"
                @input="handleInput"
              >
              </el-input>
              秒
            </el-col>
            <el-col :span="8" v-if="formData.treatmentVlaue == 1 && timeChange == 1">
              调整为:
              <el-date-picker
                v-model="timeStr"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                placeholder="选择日期时间"
                @change="changeDate"
              >
              </el-date-picker>
            </el-col>
            <el-col
              style="display: flex"
              :span="12"
              v-if="
                formData.treatmentVlaue == 2 && tableData.length == 1 && tableData[0].strExitTime
              "
            >
              <div style="height: 100%; line-height: 40px">车牌号:</div>
              <el-select
                v-model="carColor"
                placeholder="请选择"
                style="width: 80px"
                @change="carColorChang"
              >
                <el-option
                  v-for="item in optionsColor"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-form-item label="" prop="plateNumber">
                <el-input
                  v-model="formData.plateNumber"
                  placeholder="请输入内容"
                  style="width: 180px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10" v-if="formData.treatmentVlaue == 2 && tableData.length > 1">
              如需更改车牌号，请单独选择停车订单处理
            </el-col>
            <el-col :span="3" v-if="formData.treatmentVlaue == 3">
              增/减:
              <el-select v-model="optionsAddItem" placeholder="请选择" style="width: 80px" disabled>
                <el-option
                  v-for="item in optionsAdd"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="6" v-if="formData.treatmentVlaue == 3">
              <el-form-item label="金额-" prop="discountAmount">
                <el-input
                  v-model="formData.discountAmount"
                  maxlength="12"
                  placeholder="请输入内容"
                  style="width: 180px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <!-- 关闭 -->
      <div class="closewindow">
        <el-button :disabled="buttonTag" @click="finishData">提交审核</el-button>
        <el-button @click="consoleDetail">关闭窗口</el-button>
      </div>
    </el-dialog>
    <processPicture
      ref="processPictureID"
      :dialogVisible="dialogVisible"
      @consolePicture="consolePicture"
    ></processPicture>
  </div>
</template>
<script>
import processPicture from "@/components/exceptionHanding/processPicture";
import { dateFormat } from "@/common/js/public.js";
export default {
  name: "picDetail",
  data() {
    const originalPriceRule = (rule, value, callback) => {
      if (value <= 0) {
        callback(new Error("请输入大于0的数字,最多两位小数"));
      } else if (!/^[0-9]+(.[0-9]{1,2})?$/.test(value)) {
        callback(new Error("请输入大于0的数字,最多两位小数"));
      } else {
        this.reconstructData();
        callback();
      }
    };
    const checkPlate = (rule, value, callback) => {
      let rel =
        /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领无A-Z0-9]{1}[A-Z0-9]{1}[A-Z0-9]{4,5}([A-Z0-9挂学警港澳使]{1}|应急)$/;
      if (rel.test(value)) {
        this.reconstructData();
        callback();
      } else {
        callback("车牌号格式不正确");
      }
    };
    return {
      buttonTag: false,
      carIdFinish: "",
      dataParkRecordIds: "",
      maxShouldPay: "",
      timeou: null,
      expands: [],
      dialogVisible: false,
      timeChange: "1",
      timeInput: "",
      timeStr: "",
      carColor: "0",
      optionsAddItem: "1",
      formData: {
        textarea: "",
        treatmentVlaue: "",
        discountAmount: "",
        plateNumber: "",
      },
      rules: {
        textarea: [{ required: true, message: "请填写处理理由", trigger: "blur" }],
        treatmentVlaue: [{ required: true, message: "请选择处理方式", trigger: "change" }],
        discountAmount: [
          {
            required: true,
            message: "请输入金额",
            trigger: "change",
          },
          {
            validator: originalPriceRule,
            trigger: "blur",
          },
        ],
        plateNumber: [
          {
            required: true,
            message: "请输入车牌号",
            trigger: "blur",
          },
          { validator: checkPlate, trigger: "blur" },
        ],
      },
      timeChangeoptions: [
        {
          value: "1",
          label: "具体时间日期",
        },
        {
          value: "2",
          label: "记录入场时间",
        },
      ],
      options: [
        // {
        //   value: "0",
        //   label: "请选择",
        // },
        {
          value: "1",
          label: "调整出场时间",
        },
        {
          value: "2",
          label: "变更车牌号",
        },
        {
          value: "3",
          label: "更改订单金额",
        },
        {
          value: "4",
          label: "免费该订单",
        },
        {
          value: "5",
          label: "作废该订单",
        },
      ],
      optionsColor: [
        {
          value: "0",
          label: "蓝",
        },
        {
          value: "1",
          label: "黄",
        },
        {
          value: "2",
          label: "绿",
        },
        {
          value: "3",
          label: "黄绿",
        },
        {
          value: "4",
          label: "黑",
        },
        {
          value: "5",
          label: "白",
        },
      ],
      optionsAdd: [
        {
          value: "1",
          label: "减",
        },
        {
          value: "2",
          label: "加",
        },
      ],
      textarea: "",
      tableData: [
        {
          refundRecordId: "1704611834143196025",
          orderShouldPay: 1,
          refundPay: 1,
          actualRefundPay: 1,
          parkCardRefundPay: 0,
          parkDiscountRefundPay: 0,
          agioRefundPay: 0,
          refundReason: 2,
          refundReasonName: "其他",
          refundState: 1,
          refundStateName: "待审核",
          orderNumber: "1704611834143196026",
          entryTime: "1625566657367",
          exitTime: "1625570685163",
          parkRecordId: "1704530183337825954",
          payOrderId: "1704531694974419894",
          payType: 66,
          payTypeName: "支付宝支付",
          parkId: "1703234596806115715",
          parentParkId: "1703234596806115715",
          parentParkName: "招行测试车场",
          berthId: "1703235021797065306",
          carId: "1966464047719632768",
          plateNumber: "蓝:京RT4444",
          refundMode: 0,
          refundModeName: "原路退回",
          operator: "1674789159968266148",
          operatorName: "两万格",
          createdTime: "2021-07-07 15:55:26",
          updatedTime: "2021-07-07 15:55:25",
          channelType: 16,
          channelTypeDesc: "招商银行",
          devType: 2,
          devTypeDesc: "App",
        },
        {
          refundRecordId: "1704611834143196025",
          orderShouldPay: 1,
          refundPay: 1,
          actualRefundPay: 1,
          parkCardRefundPay: 0,
          parkDiscountRefundPay: 0,
          agioRefundPay: 0,
          refundReason: 2,
          refundReasonName: "其他",
          refundState: 1,
          refundStateName: "待审核",
          orderNumber: "1704611834143196026",
          entryTime: "1625566657367",
          exitTime: "1625570685163",
          parkRecordId: "1704530183337825955",
          payOrderId: "1704531694974419894",
          payType: 66,
          payTypeName: "支付宝支付",
          parkId: "1703234596806115715",
          parentParkId: "1703234596806115715",
          parentParkName: "招行测试车场",
          berthId: "1703235021797065306",
          carId: "1966464047719632768",
          plateNumber: "蓝:京RT4444",
          refundMode: 0,
          refundModeName: "原路退回",
          operator: "1674789159968266148",
          operatorName: "两万格",
          createdTime: "2021-07-07 15:55:26",
          updatedTime: "2021-07-07 15:55:25",
          channelType: 16,
          channelTypeDesc: "招商银行",
          devType: 2,
          devTypeDesc: "App",
        },
      ],
      strEntryTimeShow: "",
      strExitTimeShow: "",
      shouldPay: "",
      disabledClick: false,
    };
  },
  watch: {
    // timeInput: {
    //   handler(curVal, oldVal) {
    //     console.log("curVal", curVal);
    //     console.log("oldVal", oldVal);
    //   },
    //   deep: true,
    // },
  },
  components: {
    processPicture,
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    headerTitle(row, rowIndex) {
      return "headerTitleS";
    },
    timeErrChange() {
      this.refundRecordIdFn(this.dataParkRecordIds);
    },
    finishData() {
      let that = this;
      this.$refs.formData.validate((valid) => {
        if (valid) {
          console.log("valid", valid);
          that.submitData();
        }
      });
    },
    // 数据提交
    submitData() {
      console.log("adda");
      // let diffValue  =  this.formData.discountAmount ? this.formData.discountAmount * 100 : 0
      let appealContent = {
        exitTimeModifyType: this.timeChange - 1,
        modifyExitTime: this.timeStr,
        exitTimeModifyValue: this.timeInput,
        carId: this.carIdFinish,
        diffValue: this.formData.discountAmount,
      };

      let dataItem = {
        parkRecordId: this.dataParkRecordIds,
        parkRecordIds: this.dataParkRecordIds,
        handleReason: this.formData.textarea,
        appealType: this.formData.treatmentVlaue - 1,
        appealContent: JSON.stringify(appealContent),
      };
      // 调整出场时间且为记录入场时间状态
      if (this.formData.treatmentVlaue == 1 && this.timeChange == 2 && this.timeInput == "") {
        this.$alert("调整时间不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
      let entryTime = new Date(this.strEntryTimeShow).getTime() / 1000;
      let exitTime = new Date(this.strExitTimeShow).getTime() / 1000;
      let nowDate = new Date(new Date().getTime()) / 1000;
      let DateShow = new Date(this.timeStr).getTime() / 1000;
      console.log(entryTime, Number(this.timeInput), nowDate, "entryTime");
      // 调整出场时间且记录入场时间时对输入时间的校验
      if (this.formData.treatmentVlaue == 1 && this.timeChange == 2) {
        if (this.strExitTimeShow) {
          if (entryTime + Number(this.timeInput) >= exitTime) {
            this.$alert("调整时间不能大于或等于出场时间", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return false;
          }
        } else {
          if (entryTime + Number(this.timeInput) > nowDate) {
            this.$alert("调整时间不能大于当前时间", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return false;
          }
        }
      }
      if (this.formData.treatmentVlaue == 1 && this.timeChange == 1) {
        console.log(this.timeStr, "是多少");
        if (this.strExitTimeShow) {
          if (DateShow >= exitTime) {
            this.$alert("调整时间不能大于或等于出场时间", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return false;
          }
        } else {
          if (DateShow > nowDate) {
            this.$alert("调整时间不能大于当前时间", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return false;
          }
        }
      }
      console.log(
        this.disabledClick,
        this.strExitTimeShow,
        this.formData.treatmentVlaue,
        this.formData.discountAmount * 100,
        this.shouldPay,
        "this.strExitTimeShow"
      );
      // 更改订单金额，修改金额不能大于应收金额
      if (
        !this.disabledClick &&
        this.strExitTimeShow &&
        this.formData.treatmentVlaue == 3 &&
        (this.formData.discountAmount * 100).toFixed(2) > this.shouldPay
      ) {
        this.$alert("更改金额不能大于应收金额", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
      console.log("dataItem", dataItem);
      let url = "/acb/2.0/orderAppeal/add";
      if (this.tableData.length > 1) {
        url = "/acb/2.0/orderAppeal/addBatch";
      }

      this.$axios
        .post(url, {
          data: dataItem,
        })
        .then((res) => {
          if (res.state == 0) {
            this.$message.success(res.value);
            this.consoleDetail(1);
          }
        });
    },
    // 按照入场时间、计算出场时间
    getTimeDiff(date) {
      let timeE = this.timeInput * 1000;
      let entryTime = new Date(date).getTime();
      let newExitTime = entryTime + timeE;
      let exit = dateFormat(new Date(Number(newExitTime)), "yyyy-MM-dd HH:mm:ss");
      if (exit) {
        return exit;
      } else {
        return date;
      }
    },
    // 加一秒
    getTimeDiffAdd(date) {
      let timeE = 1 * 1000;
      let entryTime = new Date(date).getTime();
      let newExitTime = entryTime + timeE;
      let exit = dateFormat(new Date(Number(newExitTime)), "yyyy-MM-dd HH:mm:ss");
      if (exit) {
        return exit;
      } else {
        return date;
      }
    },
    // 计算停车时长
    getParkingTime(entryTime, exitTime) {
      // console.log("算时间entryTime", entryTime);
      // console.log("算时间exitTime", exitTime);
      let t1 = this.$moment(entryTime);
      let t2 = this.$moment(exitTime);
      let diff = this.$moment.duration(t2 - t1, "ms");
      let days = t2.diff(t1, "day");
      let hours = diff.get("hours");
      let mins = diff.get("minutes");
      let ss = diff.get("seconds");

      // let timeR = days + "天" + hours + "时" + mins + "分" + ss + "秒";
      let timeR = "";
      if (days + hours > 0) {
        timeR = days + "天" + hours + "时";
      }
      if (mins + ss > 0) {
        timeR = timeR + mins + "分" + ss + "秒";
      }
      // console.log(timeR);
      return timeR;
    },
    // 计算时间差值
    getParkingTimeDiff(entryTime, exitTime) {
      // console.log("计算时间差值", entryTime)
      let t1 = this.$moment(entryTime);
      let t2 = this.$moment(exitTime);
      let diff = this.$moment.duration(t2 - t1, "ms");
      let days = t2.diff(t1, "day");
      let hours = diff.get("hours");
      let mins = diff.get("minutes");
      let ss = diff.get("seconds");
      let timeR = days + hours + mins + ss;
      return timeR;
    },
    // 根据选定批量计算出场时间
    getbatchTime(entryTime, exitTime) {
      let exitTimeStr = "";
      if (!exitTime) {
        // 没有出场时间取当前时间
        let entryTime = new Date().getTime();
        let exit = dateFormat(new Date(Number(entryTime)), "yyyy-MM-dd HH:mm:ss");
        exitTime = exit;
      }
      // T0 选定时间在入场+1秒之前  入场》选定
      let entryTimeT0 = this.getTimeDiffAdd(entryTime);
      // 按照入场时间+ N秒 计算出场时间
      let diffTime = this.getTimeDiff(entryTime);
      //  选定时间和入场时间比对
      let diffEntry = this.getParkingTimeDiff(this.timeStr ? this.timeStr : diffTime, entryTimeT0);
      let diffExit = this.getParkingTimeDiff(this.timeStr ? this.timeStr : diffTime, exitTime);
      if (diffEntry >= 0) {
        exitTimeStr = entryTimeT0;
        return exitTimeStr;
      }
      // 入场>T0<出场
      if (diffEntry < 0 && diffExit >= 0) {
        exitTimeStr = this.timeStr ? this.timeStr : diffTime;
        return exitTimeStr;
      }
      // T0 > 出场
      if (diffExit < 0) {
        exitTimeStr = exitTime;
        return exitTimeStr;
      }
      return exitTimeStr;
    },
    //
    getbatchTimeS(entryTime, exitTime) {
      let exitTimeStr = "";
      if (exitTime) {
        // T0 选定时间在入场+1秒之前  入场》选定
        let entryTimeT0 = this.getTimeDiffAdd(entryTime);
        let diffTime = this.getTimeDiff(entryTime);
        let diffEntry = this.getParkingTimeDiff(diffTime, entryTimeT0);
        let diffExit = this.getParkingTimeDiff(diffTime, exitTime);
        if (diffEntry >= 0) {
          exitTimeStr = entryTimeT0;
          return exitTimeStr;
        }
        // 入场>T0<出场
        if (diffEntry < 0 && diffExit > 0) {
          exitTimeStr = diffTime;
          return exitTimeStr;
        }
        // T0 > 出场
        if (diffExit < 0) {
          exitTimeStr = exitTime;
          return exitTimeStr;
        }
      } else {
        // 没有出场时间取当前时间
        let entryTime = new Date().getTime();
        let exit = dateFormat(new Date(Number(entryTime)), "yyyy-MM-dd HH:mm:ss");
        exitTimeStr = exit;
        return exitTimeStr;
      }
      console.log("exitTimeStr", exitTimeStr);
      return exitTimeStr;
    },
    debounce(func, wait) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        func();
      }, wait);
    },
    inputChange(v) {
      this.debounce(this.search, 2000);
    },
    // inputChangeMoney() {
    //   this.debounce(this.searchMoney, 2000);
    // },
    // searchMoney() {
    //   console.log("计算金额");
    // },
    handleInput(e) {
      console.log(e);
      if (e === "0") {
        this.timeInput = 1;
      }
    },
    search() {
      // 搜索逻辑
      console.log("搜索逻辑");
      // this.getTimeDiff();
      if (this.timeInput) {
        this.reconstructData();
      } else {
        this.refundRecordIdFn(this.dataParkRecordIds);
      }
    },
    // 停车记录
    refundRecordIdFn(data) {
      this.dataParkRecordIds = data;
      console.log("refundRecordIdFnData-->", data);
      this.$axios
        .get("/acb/2.0/parkRecord/parkRecordIds", {
          data: {
            parkRecordIds: data,
          },
        })
        .then((res) => {
          // 记录接口出场时间
          for (let item of res.value) {
            item.testInitTime = item.strExitTime ? item.strExitTime : "";
          }
          this.tableData = res.value;
          this.expands = [];
          this.expands.push(res.value[0].parkRecordId);
          this.getMaxMoney();
          if (this.formData.treatmentVlaue == 4) {
            this.reconstructData();
          }
          if (res.value.length != "") {
            this.strEntryTimeShow = res.value[0].strEntryTime;
            this.strExitTimeShow = res.value[0].strExitTime;
            this.shouldPay = res.value[0].shouldPay;
          }
          console.log("重构数据初始化", this.tableData);
        });
    },
    // 于欣朝接口
    getGetDifferenceMoneyS(data) {
      console.log("data.parkRecordId", data.parkRecordId);
      let appealContent = {
        exitTimeModifyType: this.timeChange - 1,
        modifyExitTime: data.strExitTime,
        exitTimeModifyValue: this.timeInput,
        carId: this.carIdFinish,
        diffValue: this.formData.discountAmount,
      };
      let dataItem = {
        parkRecordId: data.parkRecordId,
        appealType: this.formData.treatmentVlaue - 1,
        appealContent: JSON.stringify(appealContent),
      };
      return new Promise((resolve, reject) => {
        this.$axios
          .post("/acb/2.0/orderAppeal/calcEqualise", {
            data: dataItem,
          })
          .then((res) => {
            console.log(res, "res");
            // resolve(getDifference);
            resolve(res);
          })
          .catch((e) => {
            console.log(e, "kkkkh");
            if (e.state == 415) {
              this.disabledClick = true;
            }
            // resolve(getDifference);
            resolve(e);
          });
      });
    },
    getGetDifferenceMoney(data, carId) {
      let dataTime = this.getParkingTimeDiff(data.strEntryTime, this.timeStr);
      let strEntryTimeAdd = "";
      //  出场小于入场   入场 + 1s
      if (dataTime < 0) {
        strEntryTimeAdd = this.getTimeDiffAdd(data.strEntryTime);
        this.timeStr = strEntryTimeAdd;
      }
      // console.log("data_1", data);
      let dataItem = {
        entryTime: data.strEntryTime,
        exitTime: this.timeChange == 1 ? this.timeStr : this.getTimeDiff(data.strEntryTime),
        carId: data.carId,
        parkId: data.parkId,
        parkRecordId: data.parkRecordId,
      };
      if (this.formData.treatmentVlaue == 2) {
        dataItem = {
          entryTime: data.strEntryTime,
          exitTime: data.strExitTime,
          carId: carId,
          parkId: data.parkId,
          parkRecordId: data.parkRecordId,
        };
      }

      return new Promise((resolve, reject) => {
        this.$axios
          .get("/acb/2.0/feeScene/charge/calc", {
            data: dataItem,
          })
          .then((res) => {
            // resolve(getDifference);
            resolve(res);
          })
          .catch((e) => {
            // resolve(getDifference);
            resolve(e);
          });
      });
    },
    getCarId(carColor, plateNumber, item) {
      return new Promise((resolve, reject) => {
        this.$axios
          .get("/acb/2.0/car/query/byPlateNumber", {
            data: {
              plateNumber: plateNumber,
              plateColor: carColor,
              carType: "",
            },
          })
          .then((res) => {
            this.carIdFinish = res.value.carId;
            resolve(res);
          })
          .catch((e) => {
            resolve(e);
          });
      });
    },
    changeDate() {
      // console.log("重构数据", this.timeStr);
      // console.log("expands", this.expands);
      if (this.timeStr) {
        // if (this.expands.length == 0) {
        //   this.refundRecordIdFn(this.dataParkRecordIds);
        // } else {
        //   this.reconstructData();
        // }
        this.reconstructData(1);
      } else {
        this.refundRecordIdFn(this.dataParkRecordIds);
      }
    },
    // 重构数据
    // 1:出场时间全改
    // 2:当前展开页修改金额
    // 3:没有展开过的修改样式 变成点击查看应收金额
    // 4:点击展开另一个时再次根据时间修改当前展开项目
    async reconstructData(tag) {
      let objTable = [...this.tableData];
      for (let item of objTable) {
        // 时间
        if (this.formData.treatmentVlaue == 1 && (this.timeStr || this.timeInput)) {
          // timeChange 1 调整出场时间  2 基于入场时间
          if (this.timeChange == 1) {
            item.testStrExitTime = item.testStrExitTime
              ? item.testStrExitTime
              : item.strExitTime
              ? item.strExitTime
              : "无";
            item.strExitTime = this.getbatchTime(item.strEntryTime, item.testInitTime);

            let parkingTime = this.getParkingTime(item.strEntryTime, item.strExitTime);
            item.testStrParkTime = item.testStrParkTime ? item.testStrParkTime : item.parkTime;
            item.parkTime = parkingTime;
          } else {
            item.testStrExitTime = item.testStrExitTime
              ? item.testStrExitTime
              : item.strExitTime
              ? item.strExitTime
              : "无";
            item.strExitTime = this.getbatchTime(item.strEntryTime, item.testInitTime);

            let parkingTime = this.getParkingTime(item.strEntryTime, item.strExitTime);
            // 停车时长
            item.testStrParkTime = item.testStrParkTime ? item.testStrParkTime : item.parkTime;
            item.parkTime = parkingTime;

            // item.testStrExitDataSourceName = item.testStrExitDataSourceName
            // ? item.testStrExitDataSourceName
            // : item.exitDataSourceName;
            // item.exitDataSourceName = "异常订单修改";
          }
          // 时间改动修改当前展开项、金额、先调接口
          if (this.expands[0] == item.parkRecordId) {
            // eslint-disable-next-line no-await-in-loop
            let dataDate = await this.getGetDifferenceMoneyS(item);
            if (dataDate.state != 0) {
              // this.timeStr = "";
              return;
            }
            // 应收
            item.originSumShouldPay = dataDate.value.originSumShouldPay;
            item.modifySumShouldPay = dataDate.value.modifySumShouldPay;
            // 优惠
            item.originSumAgioMoney = dataDate.value.originSumAgioMoney;
            item.modifySumAgioMoney = dataDate.value.modifySumAgioMoney;
            // 实付
            item.originSumActualMoney = dataDate.value.originSumActualMoney;
            item.modifySumActualMoney = dataDate.value.modifySumActualMoney;
            // 欠费
            item.originSumLackMoney = dataDate.value.originSumLackMoney;
            item.modifySumLackMoney = dataDate.value.modifySumLackMoney;
            // 优惠券
            item.originSumDiscount = dataDate.value.originSumDiscount;
            item.modifySumDiscount = dataDate.value.modifySumDiscount;
          }
        }
        // 车牌
        if (this.formData.treatmentVlaue == 2 && this.formData.plateNumber) {
          // 车牌改动修改当前车牌
          if (this.expands[0] == item.parkRecordId) {
            // 520 调接口成功返回carid后。在传入
            let carColor = this.getCarColor();
            let plateNumber = this.formData.plateNumber;
            // eslint-disable-next-line no-await-in-loop
            let carId = await this.getCarId(this.carColor, plateNumber, item);
            console.log("carId", carId);
            if (carId.state != 0) {
              return;
            }
            item.testPlateNumber = item.testPlateNumber ? item.testPlateNumber : item.plateNumber;
            item.plateNumber = carColor + plateNumber;
            // eslint-disable-next-line no-await-in-loop
            let dataDate = await this.getGetDifferenceMoneyS(item);
            if (dataDate.state != 0) {
              return;
            }
            // 应收
            item.originSumShouldPay = dataDate.value.originSumShouldPay;
            item.modifySumShouldPay = dataDate.value.modifySumShouldPay;
            // 优惠
            item.originSumAgioMoney = dataDate.value.originSumAgioMoney;
            item.modifySumAgioMoney = dataDate.value.modifySumAgioMoney;
            // 实付
            item.originSumActualMoney = dataDate.value.originSumActualMoney;
            item.modifySumActualMoney = dataDate.value.modifySumActualMoney;
            // 欠费
            item.originSumLackMoney = dataDate.value.originSumLackMoney;
            item.modifySumLackMoney = dataDate.value.modifySumLackMoney;
            // 优惠券
            item.originSumDiscount = dataDate.value.originSumDiscount;
            item.modifySumDiscount = dataDate.value.modifySumDiscount;
          }
        }
        // 手动修改订单
        if (this.formData.treatmentVlaue == 3) {
          // 初始值
          if (this.expands[0] == item.parkRecordId) {
            // eslint-disable-next-line no-await-in-loop
            let dataDate = await this.getGetDifferenceMoneyS(item);
            if (dataDate.state != 0) {
              // this.timeStr = "";
              return;
            }
            // 应收
            item.originSumShouldPay = dataDate.value.originSumShouldPay;
            item.modifySumShouldPay = dataDate.value.modifySumShouldPay;
            // 优惠
            item.originSumAgioMoney = dataDate.value.originSumAgioMoney;
            item.modifySumAgioMoney = dataDate.value.modifySumAgioMoney;
            // 实付
            item.originSumActualMoney = dataDate.value.originSumActualMoney;
            item.modifySumActualMoney = dataDate.value.modifySumActualMoney;
            // 欠费
            item.originSumLackMoney = dataDate.value.originSumLackMoney;
            item.modifySumLackMoney = dataDate.value.modifySumLackMoney;
            // 优惠券
            item.originSumDiscount = dataDate.value.originSumDiscount;
            item.modifySumDiscount = dataDate.value.modifySumDiscount;
          }
        }
        // 免费改订单
        if (this.formData.treatmentVlaue == 4) {
          // 应收金额
          item.originSumShouldPay = item.shouldPay;
          item.modifySumShouldPay = 0;
          // 优惠
          item.originSumAgioMoney = item.totalDiscount;
          item.modifySumAgioMoney = 0;

          // 实付
          item.originSumActualMoney = item.sumActualPay;
          item.modifySumActualMoney = 0;
          // 欠费
          item.originSumLackMoney = item.lackMoney;
          item.modifySumLackMoney = 0;
          // 优惠券
          item.originSumDiscount = item.originSumDiscount;
          item.modifySumDiscount = 0;
        }
      }
      this.tableData = objTable;
      console.log("tableData", this.tableData);
    },
    // 应收金额-手动金额 >= 0
    getDiffmoney(money, shouldPay) {
      let meneyData = 100 * money;
      let diffMoney = shouldPay - meneyData;
      if (diffMoney > 0) {
        return diffMoney;
      } else {
        return 0;
      }
    },
    // 最大应收
    getMaxMoney() {
      let shouldPayList = this.tableData.map((item) => {
        return item.shouldPay;
      });
      this.maxShouldPay = Math.max(...shouldPayList);
    },
    getCarColor() {
      let color = this.optionsColor.filter((item) => item.value == this.carColor);
      return color[0].label + ":";
    },
    getRowKeys(row) {
      return row.parkRecordId;
    },
    exChange(row, rowList) {
      console.log("row", row);
      console.log("rowList", rowList.length);
      // this.loading = true;

      var that = this;
      if (rowList.length > 0) {
        // 只展开一行//说明展开了
        that.expands = [];
        if (row) {
          that.expands.push(row.parkRecordId); // 只展开当前行id
        }
        this.reconstructData();
      } else {
        // 说明收起了
        that.expands = [];
      }
    },
    submitChange(val) {
      // this.restInitData();
      this.timeStr = "";
      this.formData.plateNumber = "";
      this.formData.discountAmount = "";
      this.carIdFinish = "";
      this.timeInput = "";
      this.refundRecordIdFn(this.dataParkRecordIds);
      console.log("val", val);
      if (this.formData.treatmentVlaue == 2 && this.tableData.length > 1) {
        this.buttonTag = true;
      } else {
        this.buttonTag = false;
      }
    },
    restInitData() {
      this.timeStr = "";
      this.formData.plateNumber = "";
      this.formData.discountAmount = "";
      this.carIdFinish = "";
      this.timeInput = "";
      this.formData.textarea = "";
    },
    lookPicture(data) {
      console.log("data", data);
      this.dialogVisible = true;
      this.$refs.processPictureID.getPic(data.entryOprNum, data.exitOprNum);
    },
    consolePicture() {
      this.dialogVisible = false;
    },
    // 1 代表从查看 进入审核成功
    consoleDetail(data) {
      console.log("ces");
      this.restInitData();
      this.formData.treatmentVlaue = "";
      let dataTag = "";
      if (data) {
        dataTag = 1;
      }
      this.$emit("consoleException", dataTag);
      this.$refs.formData.resetFields();
      // this.$emit('update:visible', false)
    },
    onCopy() {
      this.$message({
        message: "已复制",
        type: "success",
      });
    },
    carColorChang(value) {
      console.log("value", value);
      if (this.formData.plateNumber) {
        this.reconstructData();
      }
    },
  },
  props: {
    dialogVisibleException: {
      default: false,
    },
    infoSize: {
      type: Number,
      default: 6,
    },
    tableException: {
      default: function () {
        return [];
      },
    },
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.tableError {
  max-height: 575px;
  overflow: auto;
}

.mask {
  background: rgba(49, 53, 65, 0.6) no-repeat center / contain;
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.timelineStyle {
  padding-left: 150px;
}

.panoramicPhotos {
  display: flex;
}

.picBox {
  width: 200px;
  height: 150px;
  padding: 5px;
}

.bottomLine {
  border-bottom: 1px solid #F4F6FA;
}

.stateTip {
  z-index: 9999;
  background-color: #F4F6FA;
  width: 80px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  color: #0f6eff;
  border-radius: 10px;
  font-weight: 700;
}

.detailsItem {
  margin-bottom: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}

.detailsSpan {
  // width: 120px;
  text-align: left;
  display: inline-block;
  margin-right: 5px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #98A9BF;
}

.orderNumberStyle {
  color: #0f6eff;
}

.closewindow {
  display: flex;
  justify-content: right;
  margin-top: 10px;
}

// .parkingInfo {
//   font-family: 'PingFang';
//   position: relative;
//   width: 100%;
//   height: 35px;
//   line-height: 35px;
//   font-size: 12px;
//   font-weight: 500;
//   margin-bottom: 10px;
// }

.parkingInfo-on {
  position: absolute;
  left: 0;
  top: 31%;
  width: 4px;
  height: 16px;
  background: #0099fa;
}

.ustyleLine {
  text-decoration: line-through;
}

.uStyleItem {
  color: #0f6eff;
  padding-left: 4px;
}
.titlecontent {
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #2F2F51;
}
.el-table>>> .headerTitleS th {
  background: #F3F7FF;
  box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.85);
}

.el-table>>> td {
  height: 40px;
  padding: 0px;
  background: #F9FBFF;
  box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.08);
}
.parkingInfo {
  font-family: PingFangSC-Medium, PingFang SC;
  position: relative;
  width: 100%;
  height: 22px;
  line-height: 22px;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 16px;
  color: #000000;
  margin-top: 16px;
}
.parkingInfoLine {
  width: 56px;
  height: 5px;
  background: #0674FB;
  opacity: 0.28;
  transform: translateY(-10px);
}
.carDetail{
  padding-left: 48px;
}
</style>
<style lang="stylus">
.processDetails .el-dialog__body {
  padding-top: 4px;
}

.processDetails .el-dialog__header {
  padding: 0 12px;
  background-color: #3F4A56;
  height: 40px;
  line-height: 40px;
  border-radius: 3px 3px 0 0;

  .el-dialog__title {
    font-size: 14px;
    color: #fff;
  }

  .el-dialog__headerbtn .el-dialog__close {
    color: #ffffff;
    transform: translateY(-50%);
  }
}

.processDetails .el-step.is-center .el-step__line {
  left: 50%;
  right: -63%;
}

.processDetails .el-timeline-item__content {
  margin-left: -171px;
  white-space: pre;
}
</style>
